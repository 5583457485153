export enum NoticeType {
  news = 'ニュース',
  notice = 'お知らせ',
  noticeGuard = 'お知らせ（保護者への連絡）',
  guidRepr = '指導報告書',
  noticeGuardAuto = 'お知らせ（保護者への連絡-自動）',
  lateAbs = '学研チャンネル',
  stuCompliment = '褒めポイント',
  bulletinBoard = '掲示板',
  event = 'イベント・面談',
}
