const apiNoticeType = new Map<string, string>([
  ['1', 'news'],
  ['2', 'notice'],
  ['3', 'noticeGuard'],
  ['4', 'guidRepr'],
  ['5', 'noticeGuardAuto'],
  ['6', 'lateAbs'],
  ['7', 'stuCompliment'],
  ['8', 'event'],
])
export default apiNoticeType
